body {
    min-width: 100vw;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-body {
    min-width: (100vw - 2rem, 600px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fb6542;
    font-family: 'Varela Round', sans-serif;
}

.App-link {
    color: #61dafb;
}

h1 {
    margin-top: 15px;
    color: #ffd700
}

h3 {
    margin-top: 15px;
}

p {
    color: rgb(133, 133, 133)
}

.advice-container {
    margin-top: 8px;
    height: 80px;
    width: 90vw;
}

@-moz-keyframes breatheAnimation {
    0% {
        -moz-transform: scale(.8);
        transform: scale(.8)
    }
    25% {
        -moz-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -moz-transform: scale(1);
        transform: scale(1)
    }
    75% {
        -moz-transform: scale(.8);
        transform: scale(.8)
    }
    to {
        -moz-transform: scale(.8);
        transform: scale(.8)
    }
}

@-o-keyframes breatheAnimation {
    0% {
        -o-transform: scale(.8);
        transform: scale(.8)
    }
    25% {
        -o-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -o-transform: scale(1);
        transform: scale(1)
    }
    75% {
        -o-transform: scale(.8);
        transform: scale(.8)
    }
    to {
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@keyframes breatheAnimation {
    0% {
        -moz-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
    25% {
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    75% {
        -moz-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
    to {
        -moz-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8)
    }
}

@-webkit-keyframes dotAnimation {
    0% {
        top: -8px;
        left: -8px
    }
    25% {
        top: -8px;
        left: 442px
    }
    50% {
        top: 442px;
        left: 442px
    }
    75% {
        top: 442px;
        left: -8px
    }
    to {
        top: -8px;
        left: -8px
    }
}

@-moz-keyframes dotAnimation {
    0% {
        top: -8px;
        left: -8px
    }
    25% {
        top: -8px;
        left: 442px
    }
    50% {
        top: 442px;
        left: 442px
    }
    75% {
        top: 442px;
        left: -8px
    }
    to {
        top: -8px;
        left: -8px
    }
}

@-o-keyframes dotAnimation {
    0% {
        top: -8px;
        left: -8px
    }
    25% {
        top: -8px;
        left: 442px
    }
    50% {
        top: 442px;
        left: 442px
    }
    75% {
        top: 442px;
        left: -8px
    }
    to {
        top: -8px;
        left: -8px
    }
}

@keyframes dotAnimation {
    0% {
        top: -8px;
        left: -8px
    }
    25% {
        top: -8px;
        left: 442px
    }
    50% {
        top: 442px;
        left: 442px
    }
    75% {
        top: 442px;
        left: -8px
    }
    to {
        top: -8px;
        left: -8px
    }
}

.breath-frame {
    display: inline-block;
    padding: 1.875rem;
    position: relative
}

.breath-frame__box {
    width: 80vw;
    height: 80vw;
    max-width: 450px;
    max-height: 450px;
    position: relative;
    border-radius: .3125rem;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.breath-frame__dot {
    background-color: #fb6542;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    position: absolute;
    top: -8px;
    left: -8px;
    -webkit-animation: dotAnimation 16s linear infinite;
    -moz-animation: dotAnimation 16s linear infinite;
    -o-animation: dotAnimation 16s linear infinite;
    animation: dotAnimation 16s linear infinite
}

.breath-frame__lung {
    -webkit-animation: breatheAnimation 16s linear infinite;
    -moz-animation: breatheAnimation 16s linear infinite;
    -o-animation: breatheAnimation 16s linear infinite;
    animation: breatheAnimation 16s linear infinite
}

.breath-frame__text {
    font-size: 1.125rem;
    line-height: 1em;
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 0 1em;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.breath-frame__text--upper {
    top: 0;
    left: 0
}

.breath-frame__text--right {
    bottom: -1em;
    right: 0;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    -o-transform-origin: top right;
    transform-origin: top right
}

.breath-frame__text--bottom {
    bottom: 0;
    left: 0
}

.breath-frame__text--left {
    bottom: -1em;
    left: 0;
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left
}

@media screen and (max-width: 500px) {
    img {
        width: 60vw;
    } 
    
    @-webkit-keyframes dotAnimation {
        0% {
            top: -8px;
            left: -8px
        }
        25% {
            top: -8px;
            left: 78vw
        }
        50% {
            top: 78vw;
            left: 78vw
        }
        75% {
            top: 78vw;
            left: -8px
        }
        to {
            top: -8px;
            left: -8px
        }
    }

    @-moz-keyframes dotAnimation {
        0% {
            top: -8px;
            left: -8px
        }
        25% {
            top: -8px;
            left: 78vw
        }
        50% {
            top: 78vw;
            left: 78vw
        }
        75% {
            top: 78vw;
            left: -8px
        }
        to {
            top: -8px;
            left: -8px
        }
    }

    @-o-keyframes dotAnimation {
        0% {
            top: -8px;
            left: -8px
        }
        25% {
            top: -8px;
            left: 78vw
        }
        50% {
            top: 78vw;
            left: 78vw
        }
        75% {
            top: 78vw;
            left: -8px
        }
        to {
            top: -8px;
            left: -8px
        }
    }

    @keyframes dotAnimation {
        0% {
            top: -8px;
            left: -8px
        }
        25% {
            top: -8px;
            left: 78vw
        }
        50% {
            top: 78vw;
            left: 78vw
        }
        75% {
            top: 78vw;
            left: -8px
        }
        to {
            top: -8px;
            left: -8px
        }
    }
}